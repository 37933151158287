<template>
  <div class="sign-wrapper">
    <div class="sign">
      <div class="sign-up">
        <router-link class="sign-up__link" :to="{ name: 'sign-in' }">
          Already have an account? Sign in.
        </router-link>
<!--        <a class="sign-up__google" href="#">-->
<!--          <span class="sign-up__google-icon">-->
<!--            <img src="/assets/images/google.svg" alt="google">-->
<!--          </span>-->
<!--          Login with Google-->
<!--        </a>-->
      </div>
      <h1 class="sign__title">Sign up</h1>
      <form class="sign__form" @submit.prevent="onSubmitForm">

        <input v-model="email" class="sign__input" type="text" placeholder="Email">

        <div v-if="$v.email.$error">
          <small v-if="!$v.email.required" class="sign__form-error">
            This field is required
          </small>

          <small v-if="$v.email.$invalid" class="sign__form-error">
            Invalid email address
          </small>
        </div>

        <input v-model="password" class="sign__input" type="password" placeholder="Password">

        <div v-if="$v.password.$error">
          <small v-if="!$v.password.required" class="sign__form-error sign__form-error_tip">
            The password field is empty. Please enter a strong password.
          </small>

          <small v-if="!$v.password.minLength" class="sign__form-error sign__form-error_tip">
            Password must contain at least 8 characters.
          </small>

          <small v-if="!$v.password.passwordIsValid" class="sign__form-error sign__form-error_tip">
            Please, read password recommendations below
          </small>
        </div>

        <p class="sign__tip">
          Use 8 or more characters with a mix of letters, numbers & symbols.
          <b-link class="sign__tip-link" id="password-tip">How to make a strong password</b-link>
        </p>
        <b-tooltip tabindex="-1" target="password-tip" triggers="hover" placement="bottomright">
          <ul class="sign__tip-list">
            <li
              :class="{'sign__tip-list-item_green': passwordParams.hasAtLeastOneLowercase}"
              class="sign__tip-list-item">
              <span>Have at least one lower case character</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.hasAtLeastOneCapital}"
              class="sign__tip-list-item">
              <span>Have at least one capital letter</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.hasNumbers}"
              class="sign__tip-list-item">
              <span>Have at least one number</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.sameCharacters}"
              class="sign__tip-list-item">
              <span>Your password must not contain more than 2 consecutive identical characters</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.latinCharacters}"
              class="sign__tip-list-item">
              <span>Your password must contain only Latin letters</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.notSameAsAccountName}"
              class="sign__tip-list-item">
              <span>Not be the same as the account name</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.minLength}"
              class="sign__tip-list-item">
              <span>Be at least 8 characters</span>
            </li>
            <li
              :class="{'sign__tip-list-item_green': passwordParams.isCommonPassword}"
              class="sign__tip-list-item">
              <span>Not be a common password</span>
            </li>
          </ul>
        </b-tooltip>

        <input v-model="passwordConfirmation" class="sign__input" type="password" placeholder="Confirm password">

        <div v-if="$v.passwordConfirmation.$error">
          <small
            v-if="!$v.passwordConfirmation.sameAsPassword"
            class="sign__form-error"
          >
            Password and confirm password do not match
          </small>
        </div>

        <div class="sign__submit-wrapper">
          <button class="sign__submit" type="submit" :disabled="isFetching">
            Next
            <b-spinner v-if="isFetching" small variant="dark"></b-spinner>
          </button>
          <div class="sign__captcha"></div>
        </div>
      </form>
      <p class="sign__policy">
        By clicking Next you agree with <a href="#">Terms of Use</a> and <a href="">Privacy Policy</a>
      </p>
    </div>

  </div>

</template>

<script>
  import axios from 'axios'
  import {top200Passwords} from '@/utils'
  import {MODULE_NAME, SIGNUP_REQUEST} from "@/store/modules/auth";
  import {email, required, sameAs, minLength} from "vuelidate/lib/validators";

  export default {
    name: 'SignUp',
    data() {
      return {
        email: '',
        password: '',
        passwordConfirmation: ''
      }
    },
    validations: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        passwordIsValid: (v, vm) => vm.passwordParams.allFieldsIsValid,
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    },
    computed: {
      signupState() {
        return this.$store.state[MODULE_NAME].signup
      },
      isFetching() {
        return this.signupState.loading
      },
      fetchSuccess() {
        return this.signupState.data
      },
      fetchError() {
        return this.signupState.error
      },
      passwordParams() {
        const isNotEmpty = !!this.password

        const params = {
          minLength: isNotEmpty && this.$v.password.minLength,
          hasNumbers: !!this.password.match(/[0-9]/ig),
          notSameAsAccountName: this.email !== this.password,
          hasAtLeastOneCapital: this.password.match(/[A-Z]/),
          hasAtLeastOneLowercase: this.password.match(/[a-z]/),
          sameCharacters: isNotEmpty && this.checkSameCharacters(this.password),
          isCommonPassword: isNotEmpty && !top200Passwords.includes(this.password),
          latinCharacters: this.password.match(/[a-zA-Z]/)
        }

        return {
          ...params,
          allFieldsIsValid: Object.keys(params).every(item => params[item])
        }
      }
    },
    methods: {
      checkSameCharacters(str) {
        return !/(.)\1/.test(str);
      },
      async onSubmitForm() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        await this.$store.dispatch(SIGNUP_REQUEST, {
          email: this.email,
          password: this.password,
        })

        this.$router.push({name: 'sign-up-success'})
      }
    }
  }
</script>
